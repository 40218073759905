/* eslint-disable no-mixed-spaces-and-tabs */
const CONSTANTS = require('./constants');
const utils = require('./utils');
/**
 * @function
 * @description Validates a given Zip against US and CA postal code regex.
 * @param {string} value The Zip which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateZip(value, el) {
    let isOptional = this.optional(el);
    let isValid = true;
    const countryCodeID = el?.id?.includes('shippingZipCodedefault')
        ? $('#shippingCountrydefault :selected').attr('id')
        : $('#billingCountry :selected').attr('id') || $('#country :selected').attr('id') || $('#countryCode').val();
    if (countryCodeID === 'US') {
        isValid = CONSTANTS.regex.POSTAL.US.test($.trim(value));
    } else if (countryCodeID === 'CA') {
        isValid = CONSTANTS.regex.POSTAL.CA.test($.trim(value));
    } else {
        isValid = CONSTANTS.regex.POSTAL.REST.test($.trim(value));
    }
    // eslint-disable-next-line no-undef
    return isOptional || isValid;
}

/**
 * @function
 * @description Validates a given email
 * @param {string} value The email which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateEmail(value, el) {
    // eslint-disable-next-line
    let isValid = true;
    let isOptional = this.optional(el);
    isValid = CONSTANTS.regex.EMAIL.test($.trim(value), 'i');
    return isOptional || isValid;
}

/**
 * @function
 * @description Validates a given Barcode number
 * @param {string} value The Barcode number value will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateBarcode(value, el) {
    let isValid = true;
    let isOptional = this.optional(el);
    // eslint-disable-next-line
    value = value.trim();
    if (!value) {
        isValid = false;
        return isValid;
    }
    var isAlphaNumeric = false;
    var isCKSAPEnabled = $('#isCKSAPEnabled').val();
    if (isCKSAPEnabled === 'true') {
        isAlphaNumeric = CONSTANTS.regex.BARCODE.test(value);
        if (!isAlphaNumeric) {
            isValid = false;
        }
        return isValid;
    }
    if ((value.length < 18 || value.length > 19) || (value.length === 19 && !value.startsWith('999020')) || (value.length === 18 && !(value.startsWith('99') && value.endsWith('3')))) {
        isValid = false;
        return isValid;
    }
    isValid = true;
    return isOptional || isValid;
}

/**
 * @function
 * @description Validates amount
 * @param {string} value The amount which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateAmount(value, el) {
    let isOptional = this.optional(el);
    let isValid = CONSTANTS.regex.AMOUNT.test($.trim(value));
    return isOptional || isValid;
}

/**
 * @function
 * @description Validates a name
 * @param {string} value The name which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateName(value, el) {
    let isOptional = this.optional(el);
    let isValid = $.trim(value).length > 0 && CONSTANTS.regex.NAME.test($.trim(value));
    return isOptional || isValid;
}

/**
 * @function
 * @description Validates a name
 * @param {string} value The addressline value which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
 function validateAddressLine(value, el) {
    let isOptional = this.optional(el);
    let isValid = $.trim(value).length > 0 && CONSTANTS.regex.ADDRESSLINE.test($.trim(value));
    return isOptional || isValid;
}

/**
 * @function
 * @description Validates a city
 * @param {string} value The name which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateCity(value, el) {
    let isOptional = this.optional(el);
    let isValid = $.trim(value).length > 0 && CONSTANTS.regex.CITY.test($.trim(value));
    return isOptional || isValid;
}

/**
 * @function
 * @description Validates a phone-number
 * @param {string} value The name which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validatePhoneMinLimit(value, el) {
    let isOptional = this.optional(el);
    let limitPhoneNo = $(el).attr('minlength');
    let isValidFormat = CONSTANTS.regex.PHONENO.FORMAT.test($.trim(value));
    let isValid = ($.trim(value).length >= limitPhoneNo);
    return isOptional || (isValid && isValidFormat);
}

/**
 * @function
 * @description Validates a password
 * @param {string} value The password which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validatePassword(value, el) {
    let isOptional = this.optional(el);
    let isAlphaNumeric = CONSTANTS.regex.PASSWORD_ALPHANUMERIC.test($.trim(value));
    let isLengthValid = ($.trim(value).length >= 6);
    return isOptional || (isAlphaNumeric && isLengthValid);
}

/**
 * @function
 * @description Validates number
 * @param {string} value The number which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateNumber(value, el) {
    let isOptional = this.optional(el);
    let isValid = CONSTANTS.regex.NUMBERCHAR.test($.trim(value));
    return isOptional || isValid;
}

/**
 * @function
 * @description Validates number and decimal
 * @param {string} value The number which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateNumberandDecimal(value, el) {
    let isOptional = this.optional(el);
    let isValid = CONSTANTS.regex.NUMERIC_AND_DECIMAL.test($.trim(value));
    return isOptional || isValid;
}

/**
 * @function
 * @description Validate number range
 * @param {string} value The number which will be validated
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateRange(value, el) {
    let isOptional = this.optional(el);
    let isValid = true;
    let range = $(el).data('minmax');
    let minError = $(el).data('minerror') || $.validator.messages['isvalid-range'];
    let maxError = $(el).data('maxerror') || $.validator.messages['isvalid-range'];
    // eslint-disable-next-line
    value = $.trim(value);
    // eslint-disable-next-line
    value = Number(value.replace(/[^0-9\.-]+/g, ''));// Added to remove non numeric chars from value
    if (range) {
        let [min, max] = range.split('-');
        // type conversion
        [min, max] = [Number(min), Number(max)];
        // eslint-disable-next-line
        if (!!min && !!max) {
            if (value < min) {
                $.validator.messages['isvalid-range'] = minError;
                isValid = false;
            } else if (value > max) {
                $.validator.messages['isvalid-range'] = maxError;
                isValid = false;
            }
        }
    }
    return isOptional || isValid;
}

/**
 * @function
 * @description Validates a given address char against the regex
 * @param {string} value The address line value
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
var validateAddressChar = function (value) {
    // eslint-disable-next-line
    var rgx = RegExp(SitePreferences.VALID_ADDRESS_CHAR);
    var isValid = rgx.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Removes special characters from inout fields.
 * @param {DOM} e html element
 */
function removeSpecialChar(e) {
    if ($(e).hasClass('invalid-special-char')) {
        let inputValue = e.value;
        inputValue = utils.removeEmoji(inputValue);
        e.value = inputValue.replace(validator.SPECIALCHARVALIDATOR, '');
    }
}

/**
 * PO Box validation method to jQuery validation plugin.
 * @param {string} value The PO Box Address line value
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validatePOBOXAddress(value, el) {
    let isOptional = this.optional(el);
    let isValid = true;
    isValid = CONSTANTS.regex.PO_BOX.test($.trim(value.toLowerCase()));
    if (value === '') {
        isValid = true;
    }
    return isOptional || isValid;
}

/**
 * State name validation method to jQuery validation plugin.
 * @param {string} value The State name line value
 * @param {string} el The input field
 * @returns {boolean} - returns an element of the array that matched the query.
 */
function validateStateName(value, el) {
    let isOptional = this.optional(el);
    let isValid;
    if(value && el.classList.contains('billingStateIntl')){
        // validate international billing state
        isValid = value.trim().length > 0 && CONSTANTS.regex.INTL_STATE_NAME.test(value.trim()); 
    } else {
        isValid = $.trim(value).length > 0 && CONSTANTS.regex.STATE_NAME.test($.trim(value));
    }
    return isOptional || isValid;
}

// Text fields must have 'isvalid-zip' class name to be validated as phone
$.validator.addMethod('isvalid-zip', validateZip, CONSTANTS.messages.INVALID_ZIP);

// Text fields must have 'isvalid-email' class name to be validated as email
$.validator.addMethod('isvalid-email', validateEmail, CONSTANTS.messages.INVALID_EMAIL);

// Text fields must have 'isvalid-name' class name to be validated as name
$.validator.addMethod('isvalid-name', validateName, CONSTANTS.messages.INVALID_NAME);

// Text fields must have 'isvalid-number' class name to be validated as name
$.validator.addMethod('isvalid-number', validateNumber, CONSTANTS.messages.INVALID_ORDER_NUMBER);

// Text fields must have 'isvalid-range' class name to be validated as range
$.validator.addMethod('isvalid-range', validateRange, CONSTANTS.messages.INVALID_RANGE);

// Text fields must have 'isvalid-fname' class name to be validated as first name with less than 30 characters
$.validator.addMethod('isvalid-fname', validateName, CONSTANTS.messages.INVALID_FIRSTNAME);

// Text fields must have 'isvalid-phoneno' class name to be validated as first name with less than 30 characters
$.validator.addMethod('isvalid-phoneno', validatePhoneMinLimit, CONSTANTS.messages.INVALID_PHONENO);

// Text fields must have 'isvalid-lname' class name to be validated as last name with less than 30 characters
$.validator.addMethod('isvalid-lname', validateName, CONSTANTS.messages.INVALID_LASTNAME);

// Text fields must have 'isvalid-city' class name to be validated as city name with less than 30 characters
$.validator.addMethod('isvalid-city', validateCity, CONSTANTS.messages.INVALID_CITYNAME);

// Text fields must have 'isvalid-password' class name to be validated as password
$.validator.addMethod('isvalid-password', validatePassword, CONSTANTS.messages.INVALID_PASSWORD);

// Text fields must have 'isvalid-amount' class name to be validated as password
$.validator.addMethod('isvalid-amount', validateAmount, CONSTANTS.messages.INVALID_AMOUNT);

// validate Address Fields
$.validator.addMethod('isvalid-addressfield', validateAddressLine, CONSTANTS.messages.INVALID_ADDRESS);

// validate PO BOX Address
$.validator.addMethod('isvalid-PO-BOX', validatePOBOXAddress, CONSTANTS.messages.INVALID_PO_BOX);

// Text fields must have 'isvalid-barcode' class name to be validated as barcode
$.validator.addMethod('isvalid-barcode', validateBarcode, CONSTANTS.messages.INVALID_BARCODE);

// Text fields must have 'isvalid-purchaseamount' class name to be validated as purchaseamount
$.validator.addMethod('isvalid-purchaseamount', validateNumberandDecimal, CONSTANTS.messages.INVALID_PURCHASE_AMOUNT);

// Text fields must have 'isvalid-state' class name to be validated as stateName
$.validator.addMethod('isvalid-state', validateStateName, CONSTANTS.messages.INVALID_STATENAME);

// Text fields must have 'validChar' class name to be validated as validateAddressChar
if (window.Resources) {
    $.validator.addMethod('validChar', validateAddressChar, window.Resources.ADDRESS_INVALID_CHAR);
}
// eslint-disable-next-line
function disableFormSubmit($form) {
    let $submitEl;
    let config = {};
    try {
        $submitEl = $form.find('#save-continue-button');
        config = $.data($form[0], 'validator').settings;
        if (config && !config.disableForm) return;
        // Check if we want to ignore validation for a specific scenario
        if ($submitEl.hasClass('ignore-validation')) return;
        // eslint-disable-next-line
        if (($form.length && $form.find('.error').length)) {
            // eslint-disable-next-line
            $submitEl.attr({ 'disabled': 'disabled', 'aria-disabled': 'true' }).addClass('disabled');
        } else {
            // eslint-disable-next-line
            $submitEl.removeAttr('disabled aria-disabled').removeClass('disabled');
        }
    } catch (e) {
        // eslint-disable-next-line
    }
}

const settings = {
    // global form validator settings
    errorClass: 'error invalid-feedback',
    errorElement: 'span',
    onkeyup: false,
    highlight: function (element) {
        $(element).removeClass('is-invalid').addClass('error').addClass('is-invalid');
        disableFormSubmit($(element).closest('form'));
    },
    // eslint-disable-next-line
    unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass('error').removeClass('is-invalid');
        let errorId = $(element).attr('id');
        $(`span[id="${errorId}-error"]`).remove();
        disableFormSubmit($(element).closest('form'));
    },
    focusInvalid: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
    },
    invalidHandler: function (form, validator) {
        var checkoutPage = $('#checkout-main');
        if (!validator.numberOfInvalids() || !checkoutPage.length) return;
        var scrollToValue = $(validator.errorList[0].element).offset().top;
        // scroll to first error field.
        let $firstErrorElement = $(validator.errorList[0].element);
        if (utils.isDesktop()) {
            $('html, body').animate({ scrollTop: scrollToValue }, 200);
        } else {
            $firstErrorElement[0].scrollIntoView();
        }
    },
    // custom variables
    disableForm: true
};
const ONKEYUP_ONFOCUSOUT_SETTINGS = {
    errorClass: 'invalid-feedback',
    errorElement: 'div',
    onkeyup: function (element) {
        let $element = $(element);
        removeSpecialChar(element);
        // eslint-disable-next-line
        $element.attr('aria-invalid', 'false');
        $element.removeClass('is-invalid error');
        $element.siblings('.invalid-feedback').hide();
        return;
    },
    onfocusout: function (element) {
        this.element(element);
        removeSpecialChar(element);
    },
    onclick: false,
    disableForm: true,
    errorPlacement: function (error, element) {
        // eslint-disable-next-line
        if ($(element).closest('.password-container').hasClass('tick-validation')) return true;
        // eslint-disable-next-line
        return;
    }
};

const ENABLED_BUTTON_SETTINGS = {
    disableForm: false,
    invalidHandler: function (form, validator) {
        var checkoutPage = $('#checkout-main');
        if (!validator.numberOfInvalids() || !checkoutPage.length) {
            return;
        }
        // scroll to first error field.
        let $firstErrorElement = $(validator.errorList[0].element);
        let scrollToValue = $firstErrorElement.offset().top;
        if (validator.settings.parentElementClass) {
            $(validator.settings.parentElementClass).animate({ scrollTop: scrollToValue - $(validator.settings.parentElementClass).offset().top }, 200);
        } else {
            // eslint-disable-next-line
            if (utils.isDesktop()) {
                $('html, body').animate({ scrollTop: scrollToValue }, 200);
            } else {
                $firstErrorElement[0].scrollIntoView();
            }
        }
    },
    // eslint-disable-next-line
    highlight: function (element) {
        // eslint-disable-next-line
        $(element).removeClass('is-invalid').addClass('error').addClass('is-invalid').attr('aria-invalid', true);
    },
    // eslint-disable-next-line
    unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass('error').removeClass('is-invalid').attr('aria-invalid', false);
        let errorId = $(element).attr('id');
        $(`span[id="${errorId}-error"]`).remove();
    }
};

const PASSWORD_FIELD_SETTINGS = {
    highlight: function (element) {
        // eslint-disable-next-line
        $(element).removeClass('is-invalid').addClass('error').addClass('is-invalid').attr('aria-invalid', true);
        // eslint-disable-next-line
        validator.disableFormSubmit($(element).closest('form'));
        var $passwordContainer = $(element).closest('.password-container');
        if ($passwordContainer.hasClass('tick-validation')) {
            var $minLengthContainer = $(element).closest('.password-container').find('.password-validation.min-length');
            if ($.trim($(element).val()).length >= 8) {
                $minLengthContainer.addClass('parameter-valid');
            } else {
                $minLengthContainer.removeClass('parameter-valid');
            }
            var $alphaNumericContainer = $(element).closest('.password-container').find('.password-validation.alpha-numeric');
            // eslint-disable-next-line
            if (validator.PASSWORD_ALPHANUMERIC.test($.trim($(element).val()))) {
                $alphaNumericContainer.addClass('parameter-valid');
            } else {
                $alphaNumericContainer.removeClass('parameter-valid');
            }
        }
    },
    // eslint-disable-next-line
    unhighlight: function (element, errorClass, validClass) {
        let $element = $(element);
        if ($element.hasClass('mask-control')) {
            return;
        }
        $element.removeClass('error').removeClass('is-invalid');
        $element.parent().find('.invalid-feedback').removeClass('error');
        let errorId = $(element).attr('id');
        $(`span[id="${errorId}-error"]`).remove();
        // eslint-disable-next-line
        validator.disableFormSubmit($element.closest('form'));
        const $passwordContainer = $element.closest('.password-container');
        if ($passwordContainer.hasClass('tick-validation')) {
            $passwordContainer.find('.password-validation.min-length').addClass('parameter-valid');
            $passwordContainer.find('.password-validation.alpha-numeric').addClass('parameter-valid');
        }
    }
};
const validator = {
    // eslint-disable-next-line
    ...CONSTANTS.regex,
    settings,
    ONKEYUP_ONFOCUSOUT_SETTINGS,
    PASSWORD_FIELD_SETTINGS,
    ENABLED_BUTTON_SETTINGS,
    disableFormSubmit,
    // eslint-disable-next-line
    init: ($form = null, customSettings = {}, callback) => {
        // eslint-disable-next-line
        let settings;
        try {
            if (typeof customSettings === 'object') {
                settings = { ...validator.settings, ...customSettings };
            } else {
                settings = { ...validator.settings };
            }
            // eslint-disable-next-line
            if (!!$form) {  // if want to validate a particular form with specific settings.
                let formValidate = $form.validate(settings);
                if (settings.disableForm) {
                    disableFormSubmit($form);
                }
                // eslint-disable-next-line
                return formValidate;
                // eslint-disable-next-line
            } else { // if no form element is defined then parse all forms on the page.
                $('form:not(.suppress)').each(function () {
                    $(this).validate(settings);
                    if (settings.disableForm) {
                        disableFormSubmit($(this));
                    }
                });
            }
        } catch (e) {
            // eslint-disable-next-line
            console.error('There is some error in validating the form.')
        }
        // eslint-disable-next-line
        if (callback && typeof (callback) === 'function') {
            // eslint-disable-next-line
            return callback();
        }
        // eslint-disable-next-line
        return;
    },
    // eslint-disable-next-line
    initForm: (f, callback) => {
        // eslint-disable-next-line
        $(f).validate(this.settings);
        if ($(f).find('.requiredOptional').length) {
            $(f).find('.requiredOptional').rules('add', {
                required: function () {
                    var oneIsFilled = false;
                    $('.requiredOptional').each(function () {
                        if ($(this).val().length) {
                            oneIsFilled = true;
                        }
                    });
                    return !oneIsFilled;
                }
            });
        }
        // eslint-disable-next-line
        if ($(f).valid() === true && callback && typeof (callback) === 'function') {
            return callback();
        }
        // eslint-disable-next-line
    }
};
module.exports = validator;
