'use strict';
/* eslint-disable no-param-reassign */
var quickViewAnalytics = require('int_tealium_sfra/quickViewAnalytics');
var tealiumEnabled = JSON.parse($('.enable_tealium').text());
var refinementsAccordianTrack = [];

/**
 * open quick view analytics
 * @param {string} filterClass - selected filter class
 */
function updateFilterPanelEvent(filterClass) {
    if (filterClass && !refinementsAccordianTrack.includes(filterClass)) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        dataLayerAnalytics.datalayerEvent.push({
            event_name: 'sticky_filter_click',
            grid_filter_category: 'filters',
            grid_filter_value: filterClass
        });
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
        refinementsAccordianTrack.push(filterClass);
    }
}

/**
 * sorting analytics
 * @param {string} selectedId - selected sort id
 */
function sortEvent(selectedId) {
    if (selectedId) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        dataLayerAnalytics.datalayerEvent.push({
            event_name: 'grid_update_sort',
            grid_sort: selectedId
        });
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

/**
 * apply filter analytics
 * @param {string} selectedFilter - selected filter name
 * @param {string} filterClass - selected filter class
 */
function applyFilterEvent(selectedFilter, filterClass, filters, isSearch) {
    if (selectedFilter && filterClass) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var eventName = isSearch ? 'grid_add_filter,search_resultspage_genderpill_click' : 'grid_add_filter';
        dataLayerAnalytics.datalayerEvent.push({
            event_name: eventName,
            grid_filter_category: filterClass,
            grid_filter_value: selectedFilter.toString().toLowerCase(),
            grid_filters: filters.toString()
        });
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

/**
 * function to track click of color swatch on product tiles
 * @param {Object} data - product data
 */
function trackColorSwatchClick(data) {
    if (data) {
        var searchRedirect = data.page_type.indexOf('search') !== -1 || false;
        delete data.page_type;
        var colorSwatchData = { event_name: 'product_change_color', ...data };
        var pfm = quickViewAnalytics.getPFM(searchRedirect);
        colorSwatchData.product_colorswatch_location = 'plp color swatch';
        if (!!pfm) {
            colorSwatchData.product_finding_method = pfm;
        }
        var dataLayerAnalytics = {
            datalayerEvent: [colorSwatchData]
        };
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}
/**
 * Show filters analytics
 * @param {boolean} showFilterClicked - check if filters are expanded
 */
function showFiltersEvent(showFilterClicked) {
    if (!showFilterClicked) {
        var dataLayerAnalytics = {
            datalayerEvent: [{
                event_name: 'grid_expand_filters'
            }]
        };
        $('body').trigger('datalayerEvent:view', dataLayerAnalytics);
    }
}

module.exports = {
    navToPDPFromPLPAnalytics: function (productTile, searchRedirect) {
        if (tealiumEnabled) {
            var dataLayerAnalytics = {
                datalayerEvent: []
            };
            var productTileIndex = productTile.closest('.productTileShow').data('productindex');
            var datalayerAttribute = {};
            var badgeName = productTile.closest('.img-quickview-container').find('.badge-container').attr('data-badgename');
            var id = productTile.closest('.product-tile').data('mpid');
            datalayerAttribute = {
                event_name: 'grid_click_product',
                grid_click_position: productTileIndex ? productTileIndex.toString() : '',
                product_style_id: [id ? id.toString() : '']
            };
            if (badgeName && badgeName !== 'null') { // eslint-disable-line
                datalayerAttribute.product_badge_name = [badgeName];
            }
            var categoryLevel = productTile.closest('.product-tile').data('category');
            if (categoryLevel) {
                datalayerAttribute.product_dept = [categoryLevel.product_dept];
                datalayerAttribute.product_merch_category = [$('.datalayer-view-event').data('tealium-view')[0].page_name];
                datalayerAttribute.product_master_category = [categoryLevel.product_master_category];
            }
            if (!searchRedirect) {
                // eslint-disable-next-line no-undef
                cookieSet.set('navToPDP', 'browse');
            }
            // eslint-disable-next-line no-undef
            cookieSet.set('productMerchCategory', datalayerAttribute.product_merch_category.toString().replace(/\s/g, '%20'));
            dataLayerAnalytics.datalayerEvent.push(datalayerAttribute);
            $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
        }
    },
    updateFilterPanelEvent: updateFilterPanelEvent,
    sortEvent: sortEvent,
    applyFilterEvent: applyFilterEvent,
    showFiltersEvent: showFiltersEvent,
    trackColorSwatchClick: trackColorSwatchClick
};
